<template>
  <div class="inner">
    <div class="post-content min-wrap">
      <h1 class="post-title">{{ page_title }}</h1>
      <div class="the-content" v-html="content">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-content",
  props: {
    content: {
      type: String,
    },
    page_title: {
      type: String,
    }
  },
}
</script>

<style scoped>


</style>
