<template>
  <div>
    <home-content
        v-bind:content="content"
        :page_title="page_title"
    />
    <image-block-links
        :imageObjects="this.showExerciseImageMap"
    />
  </div>
</template>

<script>
import _ from "lodash";
import HomeContent from "@/components/home-content"
import ImageBlockLinks from "../components/image-block-links";
import pages from "@/assets/pages";
export default {
  name: "Main",
  components: {
    ImageBlockLinks,
    HomeContent,
  },
  props: {
  },
  data () {
    return {
      infor: null,
      content: null,
      page_title: null,
      mypages: pages,
    }
  },
  mounted() {
    this.whatPage()
  },
  // created() {
  //
  //        this.whatPage()
  //
  // },

  watch:{
    $route (to, from){
      this.whatPage()
    }
  },
  computed: {
    showExerciseImageMap: function () {
      let urlPart1X = this.$route.params.part1 ? this.$route.params.part1 : 0
      let urlPart2X = this.$route.params.part2 ? this.$route.params.part2 : 0
      let imageObjects = []

      if (urlPart1X == 'exercises' && urlPart2X == 0) {
        imageObjects = [
          // row 1
          {
            "title": "thighs, calves, core muscles",
            "img": "/img/adult_dribble.png",
            "url": "/exercises/swervingdodging/"
          },
          {
            "title": "stomach",
            "img": "/img/Inclined_ab_curl.png",
            "url": "/exercises/abdominal-curls-2/"
          },
          {
            "title": "chest, triceps, core body",
            "img": "/img/Dip_bars.png",
            "url": "/exercises/paired-dip-bars/"
          },
          // row 2
          {
            "title": "buttocks, thighs, calves",
            "img": "/img/Forward_jump.png",
            "url": "/exercises/jumps-forwardsbackwards/"
          },
          {
            "title": "upper back, biceps, triceps, chest",
            "img": "/img/Pull_press_bars.png",
            "url": "/exercises/pull-press-bars/"
          },
          {
            "title": "thighs, calves, buttocks, core muscles",
            "img": "/img/In_out.png",
            "url": "/exercises/ladder-drill-2/"
          },
          // row 3
          {
            "title": "thighs, calves, buttocks, core muscles",
            "img": "/img/Run_through.png",
            "url": "/exercises/ladder-drill/"
          },
          {
            "title": "upper arms, chest, core body",
            "img": "/img/Press-up_arms2.png",
            "url": "/exercises/press-ups/"
          },
          {
            "title": "buttocks, thighs, calves",
            "img": "/img/Side_step.png",
            "url": "/exercises/step-ups-lift/"
          },
          // row 4
          {
            "title": "thighs, calves, core muscles",
            "img": "/img/Accelerated_Run-2.png",
            "url": "/exercises/bleep-test/"
          },
          {
            "title": "stomach, core body",
            "img": "/img/Leg_raise_rack-1.png",
            "url": "/exercises/leg-raise/"
          },
          {
            "title": "Buttocks, thighs, calves",
            "img": "/img/Box_jumps.png",
            "url": "/exercises/arc-of-blocks/"
          },
          // row 5
          {
            "title": "buttocks, thighs, calves",
            "img": "/img/Grid_hop.png",
            "url": "/exercises/grid-hop/",
          },
          {
            "title": "upper arms, forearms, shoulders, chest",
            "img": "/img/Triceps_dips_single.png",
            "url": "/exercises/tripcep-dips/",
          },
          {
            "title": "thighs, buttocks, calves, core muscles",
            "img": "/img/Hopscotch-1.png",
            "url": "/exercises/ladder-drill-3/",
          },
          // row 5
          {
            "title": "thighs, calves, core muscles",
            "img": "/img/Accelerated_Run-2-1.png",
            "url": "/exercises/acceleration/",
          },
          {
            "title": "stomach",
            "img": "/img/Inclined_ab_curl.png",
            "url": "/exercises/abdominal-curls-2/",
          },
          {
            "title": "buttocks, thighs, calves",
            "img": "/img/Step_up-1.png",
            "url": "/exercises/step-ups/",
          },
        ]
      }

      if (urlPart1X == 'games' && urlPart2X == 0) {
        imageObjects = [
          {
            "title": "SPUD",
            "img": "/img/kids_ball_catch-300x283.png",
            "url": "/games/spud/",
          },
          {
            "title": "Cambridge City Statues",
            "img": "/img/Statues-212x300.png",
            "url": "/games/cambridge-city-statues/",
          },
          {
            "title": "Swerve Poles",
            "img": "/img/Kids_dribble-300x249.png",
            "url": "/games/swerve-poles-footy/",
          },
          {
            "title": "Around the World",
            "img": "/img/Grid_hop-300x300.png",
            "url": "/games/around-the-world/",
          },
          {
            "title": "Simon Says",
            "img": "/img/simon-says-768x768.png",
            "url": "/games/simon-says/",
          },
          {
            "title": "Hopscotch",
            "img": "/img/Hopscotch-1-300x500.png",
            "url": "/games/hopscotch/",
          },
          {
            "title": "Lilywhites lily pads",
            "img": "/img/Jumping_blocks-768x714.png",
            "url": "/games/lilywhite-lily-pads/",
          }
        ]

      }
      return imageObjects
    }
  },
  methods: {
    whatPage: function () {
      let urlPart1 = this.$route.params.part1 ? this.$route.params.part1 : '0'
      let urlPart2 = this.$route.params.part2 ? this.$route.params.part2 : '0'
      // console.log("this.$route.params " + JSON.stringify(this.$route.params))
      // console.log("urlPart1 ", urlPart1);
      // console.log("urlPart2 ", urlPart2);
      let page = _.find(this.mypages, { 'url_part_1': urlPart1, 'url_part_2': urlPart2 });

      this.content = page.content;
      this.page_title = page.page_title
    },
  },
}
</script>

<style scoped>

</style>
