<template>
  <div class="post-content min-wrap">
    <div
        v-for="(item, index) in imageObjects"
        :key="item.index"
        class="square"
    >
      <router-link :to="item.url" @click.native="goto()">
        <div class="content">
          <span>{{ item.title }}</span>
          <img :src="item.img">
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "image-block-links",
  props: {
    items: {
      // type: [Array, Object],
      // default: [
      //
      //     {
      //         "title": "buttocks, thighs, calves",
      //         "img": "http://localhost:8081/img/Step_up.png",
      //         "url": "/exercises/step-ups/",
      //     },
      //     {
      //         "title": "thighs, calves, buttocks, core muscles",
      //         "img": "http://localhost:8081/img/Hopscotch.png",
      //         "url": "/exercises/ladder-drill-3/",
      //     },
      //     {
      //         "title": "buttocks, thighs, calves",
      //         "img": "http://localhost:8081/img/In_out.png",
      //         "url": "/exercises/ladder-drill-2/",
      //     },
      //
      //
      //     {
      //         "title": "buttocks, thighs, calves",
      //         "img": "http://localhost:8081/img/Step_up.png",
      //         "url": "/exercises/pull-press-bars/",
      //     },
      //     {
      //         "title": "thighs, calves, buttocks, core muscles",
      //         "img": "http://localhost:8081/img/Hopscotch.png",
      //         "url": "/exercises/abdominal-curls-2/",
      //     },
      //     {
      //         "title": "buttocks, thighs, calves",
      //         "img": "http://localhost:8081/img/In_out.png",
      //         "url": "/exercises/acceleration/",
      //     },
      //
      //     {
      //         "title": "upper arms, forearms, shoulders, chest",
      //         "img": "/img/Triceps_dips.png",
      //         "url": "/exercises/tripcep-dips/",
      //     },
      //     {
      //         "title": "thighs, calves, buttocks, core muscles",
      //         "img": "http://localhost:8081/img/Hopscotch.png",
      //         "url": "/exercises/grid-hop/",
      //     },
      //     {
      //         "title": "buttocks, thighs, calves",
      //         "img": "http://localhost:8081/img/In_out.png",
      //         "url": "/exercises/arc-of-blocks/",
      //     },
      //
      //     {
      //         "title": "stomach, core body",
      //         "img": "http://localhost:8081/img/Leg_raise_rack-1.png",
      //         "url": "/exercises/leg-raise/",
      //     },
      //     {
      //         "title": "thighs, calves, buttocks, core muscles",
      //         "img": "http://localhost:8081/img/Hopscotch.png",
      //         "url": "/exercises/bleep-test/",
      //     },
      //     {
      //         "title": "buttocks, thighs, calves",
      //         "img": "http://localhost:8081/img/In_out.png",
      //         "url": "/exercises/step-ups-lift/",
      //     },
      //
      //
      //     {
      //         "title": "buttocks, thighs, calves",
      //         "img": "http://localhost:8081/img/Step_up.png",
      //         "url": "/exercises/press-ups/",
      //     },
      //     {
      //         "title": "thighs, calves, buttocks, core muscles",
      //         "img": "http://localhost:8081/img/Hopscotch.png",
      //         "url": "/exercises/ladder-drill/",
      //     },
      //     {
      //         "title": "buttocks, thighs, calves",
      //         "img": "http://localhost:8081/img/In_out.png",
      //         "url": "/exercises/jumps-forwardsbackwards/",
      //     },
      //
      //
      //     {
      //         "title": "buttocks, thighs, calves",
      //         "img": "http://localhost:8081/img/Step_up.png",
      //         "url": "/exercises/paired-dip-bars/",
      //     },
      //     {
      //         "title": "thighs, calves, buttocks, core muscles",
      //         "img": "http://localhost:8081/img/Hopscotch.png",
      //         "url": "/exercises/abdominal-curls-2/",
      //     },
      //     {
      //         "title": "buttocks, thighs, calves",
      //         "img": "http://localhost:8081/img/In_out.png",
      //         "url": "/exercises/swervingdodging/",
      //     }
      //
      // ]

    },
    imageObjects: {
    },

  },
  methods: {
    goto() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
    }
  }
}
</script>

<style lang="scss" scoped>

html,
body {
  margin: 0;
  padding: 0;
}
.container {
  text-align: center;
}

.square {
  position: relative;
  width: 32.33333%;
  margin: 0.33333%;
  float: left;
  overflow: hidden;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.36);
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: rgba(0,0,0,0.05);
    -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
  }
}
.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}



a {
  color: #2a3437;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  border: solid #fff 2rem;
  /**/


  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 90%;
      height: auto;
      max-height: 90%;
    }

    span {
      width: 100%;
      bottom: 0;
      padding: 0.5rem;
      background-color: rgba(255,255,255,0.2);
      position: absolute;
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
      font-family: 'Special Elite', 'Helvetica', Arial, sans-serif;
    }

  }


  /**/
  &:hover {

    img {
      opacity: 1;
    }
  }
}
</style>
