import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Main.vue")
  },
  {
    path: '/:part1/',
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Main.vue")
  },
  {
    path: '/:part1/:part2',
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Main.vue")
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
