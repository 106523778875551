<template>
  <div>
    <header-area/>
    <div id="main-wrap">
      <router-view />
    </div>
    <footer-area/>
  </div>
</template>

<script>
import HeaderArea from "@/components/header-area"
import FooterArea from "@/components/footer-area"

export default {
  name: "App",
  components: {
    HeaderArea,
    FooterArea,
  },
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>
<style lang="scss">
</style>
