<template>
  <footer class="main-footer">
    <div class="footer-wrap max-wrap">
      <div class="footer-details">
        <div class="footer-copyright">The activities in this website are suggestions only and you use this space
          at your own risk.
          Mitchams Park Active Landscape is designed to be used by all ages. However, the outdoor gym area is
          for ages 12+ only.
          Children under the age of 10 to be accompanied by an adult.
          No dogs or bottles allowed.
          To report a fault or hazard contact Remus Management Ltd on 01722 328685
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer-area"
}
</script>

<style scoped>

</style>
