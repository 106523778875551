<template>
  <div class="header-wrap">
    <header class="header">
      <div class="site-logo plain">
        <div class="site-logo-inner">
          <div class="site-title">
            <a href="/" title="Go to Home">
              Field of Play: Active Landscape
            </a>
          </div>
          <div class="site-tagline">get fit, have fun, enjoy life</div>
        </div>
      </div>
      <main-menu></main-menu>
<!--      <main-menu-2></main-menu-2>-->
    </header>
  </div>
</template>

<script>
import MainMenu from "./main-menu"
// import MainMenu2 from "@/components/main-menu-2";
export default {
  name: "header-area",
  components: {
    // MainMenu2,
    MainMenu
  },
}
</script>
