import { render, staticRenderFns } from "./footer-area.vue?vue&type=template&id=4213adbc&scoped=true&"
import script from "./footer-area.vue?vue&type=script&lang=js&"
export * from "./footer-area.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4213adbc",
  null
  
)

export default component.exports