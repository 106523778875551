<template>
  <div class="header">
    <nav id="nav" role="navigation">
      <div>
        <ul>
          <li>
            <router-link to="/">Welcome</router-link>
          </li>
          <li>
            <router-link to="/about/">About</router-link>
            <ul class='children'>
              <li>
                <router-link to="/about/the-project-kickstart/">kickstART: the big project</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/getting-started/">Get ready!</router-link>
            <ul class='children'>
              <li>
                <router-link to="/getting-started/about-sub-page/">STRENGTH: plyometric exercise
                </router-link>
              </li>
              <li>
                <router-link to="/getting-started/about-sub-page-2/">POSTURE: core strength
                </router-link>
              </li>
              <li>
                <router-link to="/getting-started/cardio-exercise/">SPEED: acceleration exercise
                </router-link>
              </li>
              <li>
                <router-link to="/getting-started/football-drills/">AGILITY: football drills
                </router-link>
              </li>
            </ul>
          </li>

          <li class="page_item page-item-37 page_item_has_children">
            <router-link to="/exercises/">Exercises</router-link>
            <ul class='children'>
              <li>
                <router-link to="/exercises/ladder-drill/">Ladder drill 1</router-link>
              </li>
              <li>
                <router-link to="/exercises/abdominal-curls-2/">Abdominal curl</router-link>
              </li>
              <li>
                <router-link to="/exercises/acceleration/">Acceleration</router-link>
              </li>
              <li>
                <router-link to="/exercises/arc-of-blocks/">Block jumps</router-link>
              </li>
              <li>
                <router-link to="/exercises/grid-hop/">Grid hop</router-link>
              </li>
              <li>
                <router-link to="/exercises/jumps-forwardsbackwards/">Jumps</router-link>
              </li>
              <li>
                <router-link to="/exercises/ladder-drill-2/">Ladder drill 2</router-link>
              </li>
              <li>
                <router-link to="/exercises/ladder-drill-3/">Ladder drill 3</router-link>
              </li>
              <li>
                <router-link to="/exercises/leg-raise/">Leg raise</router-link>
              </li>
              <li>
                <router-link to="/exercises/paired-dip-bars/">Paired dip bars</router-link>
              </li>
              <li>
                <router-link to="/exercises/press-ups/">Press ups</router-link>
              </li>
              <li>
                <router-link to="/exercises/pull-press-bars/">Pull &#038; press bars</router-link>
              </li>
              <li>
                <router-link to="/exercises/step-ups-lift/">Side step-ups</router-link>
              </li>
              <li>
                <router-link to="/exercises/step-ups/">Step-ups</router-link>
              </li>
              <li>
                <router-link to="/exercises/swervingdodging/">Swerve poles</router-link>
              </li>
              <li>
                <router-link to="/exercises/tripcep-dips/">Tricep dips</router-link>
              </li>
              <li>
                <router-link to="/exercises/bleep-test/">Bleep test</router-link>
              </li>
            </ul>
          </li>


          <li>
            <router-link to="/games/">Games</router-link>
            <ul class='children'>
              <li>
                <router-link to="/games/around-the-world/">Around the world</router-link>
              </li>
              <li>
                <router-link to="/games/cambridge-city-statues/">Cambridge City statues</router-link>
              </li>
              <li>
                <router-link to="/games/hopscotch/">Hopscotch</router-link>
              </li>
              <li>
                <router-link to="/games/lilywhite-lily-pads/">Lilywhite lily pads</router-link>
              </li>
              <li>
                <router-link to="/games/simon-says/">Simon says</router-link>
              </li>
              <li>
                <router-link to="/games/spud/">SPUD</router-link>
              </li>
              <li>
                <router-link to="/games/swerve-poles-footy/">Swerve poles: footy</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "main-menu"
}
</script>

<style scoped lang="scss">
.header nav#nav {

  a {
    color: #fff;
    -o-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;

    &.router-link-exact-active {
      color: #e90000;
    }

    &:hover {
      color: #e90000;
      opacity: 0.7;
    }
  }

}

</style>
